import { Box, Button, Image } from "@chakra-ui/react";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../services/auth/userContext";
import { LogoutButton } from "../auth/Login";

const TopBar = () => {
  const { user } = useContext(UserContext);
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Link to="/">
          <Image
            height="40px"
            src="/neuroventis_logo.png"
            alt="Neuroventis Logo"
          />
        </Link>
        <Box display="flex" alignItems="center">
          {user && user.role && user.role === "administrator" && (
            <Link to="/admin" style={{marginRight: '10px'}}>
              <Button variant="link" colorScheme="blue">
              Admin Section
              </Button>
            </Link>
            
          )}
          { user && <LogoutButton /> }
        </Box>
      </Box>
      <hr style={{ borderWidth: "1px", marginTop: "20px" }} />
    </>
  );
};

export default TopBar;
