import { Center, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "../api/base";
import CreateAccount from "../components/auth/CreateAccount";
import { ErrorText } from "../components/common/Texts";

const Invitation = () => {
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [invitation, setInvitation] = useState();

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    get(`/invitations/token/${token}`)
      .then((response) => {
        setIsLoading(false);
        if (response.status !== 200) {
          console.log("Got", response);
            setError(response.data.result);
            return;
        }

        setInvitation(response.data.result);
      })
      .catch((err) => {
        let message = err.message;
        if (err.response && err.response.data) {
          message = err.response.data.result;
        }
        setError(message);
        setIsLoading(false);
      });
  }, [token]);

  return (
    <>
      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
      {!isLoading && error && (
        <ErrorText>
          Something went wrong fetching the invitation: {error}
        </ErrorText>
      )}
      { !isLoading && invitation && <CreateAccount invitation={invitation} /> }
    </>
  );
};

export default Invitation;
