import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

const DEFAULT_BUTTON_TEXT = "Ok";

const ModalDialogue = ({ title, children, isOpen, onClose, onMainAction, buttonText, buttonColor, isDisabled }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button colorScheme={buttonColor || "blue"} mr={3} onClick={onMainAction} isDisabled={isDisabled || false}>
            { buttonText || DEFAULT_BUTTON_TEXT }
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDialogue;
