import React from "react";
import { Box } from "@chakra-ui/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginView from "./Login";
import InvitationView from "./Invitation";
import TopBar from "../components/common/TopBar";
import PasswordInstructions from "./PasswordInstructions";
import PasswordReset from "./PasswordReset";

const Anonymous = () => {
  return (
          <Box height="100vh" p={8} width="100%">
            <Router>
              <TopBar />            
              <Switch>
                <Route path="/invitations/:token">
                  <InvitationView />
                </Route>
                <Route path="/login">
                  <LoginView />
                </Route>
                <Route path="/passwordinstructions">
                  <PasswordInstructions />
                </Route>
                <Route path="/passwordreset/:token">
                  <PasswordReset />
                </Route>
                <Route path="/">
                  <LoginView />
                </Route>
              </Switch>
            </Router>
          </Box>
  );
};

export default Anonymous;
