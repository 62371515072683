import React, { useContext, useEffect } from "react";
import { Box, Center, Spinner } from "@chakra-ui/react";
import { UserContext } from "./services/auth/userContext";
import Main from "./views/Main";
import AnonymousView from "./views/Anonymous";

const App = () => {
  const { user, fetchUser, isLoading } = useContext(UserContext);

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Box height="100vh">
      { isLoading && <Center> <Spinner /> </Center>}
      {!isLoading && user && <Main /> }
      {!isLoading && !user && <AnonymousView />}
    </Box>
  );
};

export default App;
