import { Center, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { post } from "../../api/base";
import { EmphasisText, ErrorText } from "../common/Texts";

const ResendInvitation = ({ invitation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setError(false);
    post(`/invitations/resend/${invitation.token}`)
    .then(response => {
        setIsLoading(false);
        if (response.status !== 200) {
            console.error("Something went wrong generating the new invitation", response.statusText);
            setError(true);
            return ;
        }
        
        setError(false);
    })
    .catch(err => {
        setIsLoading(false);
        console.error("Error triggering reinvitation", err.message);
        setError(true);
    });
  }, [invitation]);

  return (
    <>
      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
      {!isLoading && error && <ErrorText>Something went wrong, cannot send a new invitation link. Please contact the Neuroventis team.</ErrorText>}
    {!isLoading && !error && <EmphasisText>A new invitation link has been sent to your email address. Please check it</EmphasisText>}
    </>
  );
};

export default ResendInvitation;
