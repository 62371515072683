import React, { useEffect, useState } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import ParticipantsList from "./ParticipantsList";
import { StudyContext } from "../services/study/studyContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Participant from "./Participant";
import TopBar from "../components/common/TopBar";
import { ErrorText } from "../components/common/Texts";
import { get } from "../api/base";
import Admin from "./Admin";
import env from '../env.json';

const Main = () => {
  const [study, setStudy] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { REACT_APP_STUDY } = env;
  useEffect(() => {
    setLoading(true);
    get(`/studies/${REACT_APP_STUDY}`)
      .then(response => {
        setLoading(false);

        if (response.status === 200) {
          setError(false);
          const { result } = response.data;
          setStudy(result);
        } else {
          setError(true);
        }
      })
      .catch(err => {
        setLoading(false);
        setError(true);
        console.error("An error has occurred fetching the study information", err.message);
      });
  }, []);

  return (
    <>
      {loading && <Spinner />}
      {!loading && error && (
        <ErrorText>Something went wrong loading the Study</ErrorText>
      )}
      {!loading && !error && study && (
        <StudyContext.Provider value={study}>
          <Box height="100vh" p={8} width="100%">
            <Router>
            <TopBar />
            
              <Switch>
                <Route path="/admin">
                  <Admin />
                </Route>
                <Route path="/participant/:id">
                  <Participant />
                </Route>
                <Route path="/">
                  <ParticipantsList />
                </Route>
              </Switch>
            </Router>
          </Box>
        </StudyContext.Provider>
      )}
    </>
  );
};

export default Main;
