import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
  Text,
  Input,
  Button
} from "@chakra-ui/react";
import React, { useState, useRef } from "react";
import { Subtitle } from "../common/Texts";
import { DeleteIcon } from '@chakra-ui/icons'
import ModalDialogue from "../common/ModalDialogue";
import axios from 'axios';
import env from '../../env.json';

const { REACT_APP_SERVER_URL } = env;
const UsersTable = ({ users }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null)
  const deleteConfirmation = useRef(null);

  const deleteUser = async () => {
    await axios.delete(
      `${REACT_APP_SERVER_URL}/users/${selectedUser._id}`,
      { withCredentials: true }
    );
    const userIndex = users.findIndex(user => user._id === selectedUser._id);
    users.splice(userIndex, 1)
    updateDeleteModalDisplay();
  }

  const updateDeleteModalDisplay = (user) => {
    showDeleteModal ? setSelectedUser(null) : setSelectedUser(user);
    setShowDeleteModal(!showDeleteModal);
  }

  const verifyDeletionEntry = () => {
    if (deleteConfirmation && deleteConfirmation.current && deleteConfirmation.current.value === selectedUser.email)
      setIsDeleteButtonDisabled(false)
    else if (!isDeleteButtonDisabled)
      setIsDeleteButtonDisabled(true)
  }

  return (
    <>
    {
      showDeleteModal && 
        <ModalDialogue
          title="Are you sure you want to delete this account ?"
          onClose={updateDeleteModalDisplay}
          isOpen={showDeleteModal}
          buttonText={"DELETE"}
          buttonColor={'red'}
          isDisabled={isDeleteButtonDisabled}
          onMainAction={deleteUser}
        >
          <Text>Please type account email address <strong>{selectedUser.email}</strong> in order to confirm the deletion</Text>
          <Input type="text" ref={deleteConfirmation} onChange={verifyDeletionEntry}/>
          </ModalDialogue>
    }
    <Subtitle>Registered users</Subtitle>
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>Role</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <Tr key={user._id}>
              <Td>{user.email}</Td>
              <Td>{user.role}</Td>
              <Td><IconButton 
                    colorScheme='red'
                    aria-label='Delete user'
                    icon={<DeleteIcon />}
                    onClick={() => updateDeleteModalDisplay(user)}
                  /></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
    </>
  );
};

export default UsersTable;
