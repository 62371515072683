import { useState, useEffect } from 'react';
import { groupByMonthAndWeek, calculateMovingAverage } from '../utils';

const useMovingAverage = (seizures, startDate, endDate) => {
  const [movingAverage, setMovingAverage] = useState([]);

  useEffect(() => {
    if (seizures && seizures.length > 0) {
      const sortedSeizureDates = seizures
        .map((seizure) => new Date(seizure.date))
        .sort((a, b) => a - b);

      const groups = groupByMonthAndWeek(sortedSeizureDates, startDate);
      const transformedData = calculateMovingAverage(groups, startDate, endDate);

      setMovingAverage(transformedData);
    }
  }, [seizures, startDate, endDate]);

  return movingAverage;
};

export default useMovingAverage;
