import React from "react";
import useMovingAverage from "../../hooks/useMovingAverage";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text
} from "@chakra-ui/react";
import NoContentBox from "../common/NoContentBox";
import FlexRow from "../common/FlexRow";

const MovingAverageTable = ({ seizures, startDate, endDate }) => {
	const movingAverage = useMovingAverage(seizures, startDate, endDate);

  const headers = [
    {
      title: "Month",
      fieldName: "Month",
      canFilter: false,
      dataType: "string",
    },
    {
      title: "Week Number",
      fieldName: "Week",
      canFilter: false,
      dataType: "string",
    },
	{
		title: 'Count of seizures per week',
		fieldName: 'averageSeizuresPerWeek',
		canFilter: false,
		dataType: 'string'
	},
	{
		title: "Avg. seizures last 28 days",
		fieldName: "movingAverage",
		canFilter: false,
		dataType: "string",
	},
  ];

  return (
    <>
      
    	<>
			<TableContainer marginY={6} overflowX="unset" overflowY="unset">
			<Table variant="simple">
				<Thead backgroundColor="blue.100" position="sticky" top={0} zIndex="docked">
				<Tr>
					{headers.map((header) => (
					<Th key={header.fieldName}>
						<FlexRow>
						<Text>{header.title}</Text>
						</FlexRow>
					</Th>
					))}
				</Tr>
				</Thead>
				<Tbody>
				{movingAverage.map((ma, index) => (
					<Tr key={index}>
					<Td>{ma.Month}</Td>
					<Td>{ma.Week}</Td>
					<Td>{ma.averageSeizuresPerWeek}</Td>
					<Td>{ma.movingAverage}</Td>
					</Tr>
				))}
				</Tbody>
			</Table>
			</TableContainer>
      </>
	  {(!movingAverage || movingAverage.length === 0|| !seizures || seizures.length === 0) && (
        <NoContentBox>No moving average available</NoContentBox>
      )}
    </>
  );
};

export default MovingAverageTable;
