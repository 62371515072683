import { Box, Button, Spinner, useToast } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { get, post } from "../api/base";
import SetPassword from "../components/auth/SetPassword";
import { ErrorText, LinkText, Subtitle } from "../components/common/Texts";

const PasswordReset = () => {
  const { token } = useParams();
  const [passwordReset, setPasswordReset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const passwordRef = useRef();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isLinkStillValid, setIsLinkStillValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();
  const history = useHistory();
  const isValid = (expirationTime) => new Date(expirationTime) > new Date();

  useEffect(() => {
    setIsLoading(true);
    get(`/passwordresets/${token}`)
      .then((response) => {
        setIsLoading(false);
        if (response.status !== 200) {
          return;
        }

        setPasswordReset(response.data.result);
        
        setIsLinkStillValid(isValid(response.data.result.expirationTime));
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error while getting password reset", err.message);
      });
  }, [token]);

  const resetPassword = () => {
    // check before sending it!

    if (!isValid(passwordReset.expirationTime)) {
        setIsLinkStillValid(false);
        return;
    }

    post(`/users/password/reset/${token}`, { password: passwordRef.current })
    .then((response) => {
        setIsLoading(false);
        if (response.status !== 200) {
          console.error("Error reseting password: ", response.statusText);
          setError(
            "An error has occurred, please try again, if it persists contact the Neuroventis team"
          );
          return;
        }
        setSuccess(true);

        setTimeout(() => {
          history.push("/login");
        }, 5000);

        toast({
          title: "Password updated.",
          description:
            "Your password has been updated successfully. You'll be redirected to the login page soon",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error("Error reseting password: ", err.message);
        setError(
          "An error has occurred, please try again, if it persists contact the Neuroventis team"
        );
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && passwordReset && (
        <Box display="flex" justifyContent="center">
          <Box width="2xl">
            <Subtitle>Reset your password</Subtitle>
            <Box marginTop={10}>
              {!isLinkStillValid && (
                <>
                <ErrorText>
                  This link has expired. Please request a new one:
                </ErrorText>
                <LinkText><Link to="/passwordinstructions">Get new reset password email</Link></LinkText>
                </>
              )}
              {isLinkStillValid && (
                <>
                  <SetPassword
                    passwordRef={passwordRef}
                    setIsValid={setIsPasswordValid}
                  />
                  <Button
                    marginTop={6}
                    disabled={!isPasswordValid || success}
                    colorScheme="blue"
                    onClick={resetPassword}
                    isLoading={isLoading}
                  >
                    Update Password
                  </Button>
                  { error && <ErrorText>{error}</ErrorText> }
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PasswordReset;
