import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

const InvitationForm = ({ emailRef }) => {
  return (
    <>
      <FormControl>
        <FormLabel>Email Address</FormLabel>
        <Input
          type="email"
          onChange={(e) => {
            emailRef.current = e.target.value;
          }}
        />
      </FormControl>
    </>
  );
};

export default InvitationForm;
