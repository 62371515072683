import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils";
import { LinkText } from "../common/Texts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import FlexRow from "../common/FlexRow";

const ParticipantsTable = ({
  loading,
  error,
  participants,
  onHeaderClick,
  onFilterClick,
}) => {
  const headers = [
    {
      title: "Participant ID",
      fieldName: "participantId",
      canFilter: true,
      dataType: "string",
    },
    {
      title: "Country",
      fieldName: "siteCountry",
      canFilter: true,
      dataType: "string",
    },
    {
      title: "Site",
      fieldName: "siteName",
      canFilter: true,
      dataType: "string",
    },
    {
      title: "Invitation Date",
      fieldName: "startDate",
      dataType: "date",
    },
    {
      title: "Withdrawal notification date",
      fieldName: "endDate",
      dataType: "date",
    },
    {
      title: "Questionnaire status",
      fieldName: "lastQuestionnaireStatus",
      dataType: "string",
    },
    {
      title: 'Has "other" seizures',
      fieldName: "countOtherSeizures",
      dataType: "number",
    },
  ];
  return (
    <TableContainer marginY={6} overflowX="unset" overflowY="unset">
      <Table borderWidth={1}>
        <Thead backgroundColor="blue.100" position="sticky" top={0} zIndex="docked">
          <Tr>
            {headers.map((header) => (
              <Th>
                <FlexRow>
                  <FontAwesomeIcon icon={faArrowUp} />
                  <FontAwesomeIcon icon={faArrowDown} />
                  <Text
                    cursor="pointer"
                    onClick={() => {
                      onHeaderClick(header.fieldName, header.dataType);
                    }}
                    marginLeft={2}
                  >
                    {header.title}
                  </Text>
                  {header.canFilter && (
                    <FontAwesomeIcon
                      icon={faFilter}
                      size="lg"
                      cursor="zoom-in"
                      onClick={() => {
                        onFilterClick(header.fieldName);
                      }}
                      style={{ marginLeft: "4px" }}
                    />
                  )}
                </FlexRow>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {loading && <Spinner />}
          {!loading && error && (
            <Text textColor="red.400">Something went wrong!</Text>
          )}
          {!loading &&
            !error &&
            participants &&
            participants.map((participant) => (
              <Tr key={participant.id}>
                <Td>
                  <Link to={`/participant/${participant.id}`}>
                    <LinkText>{participant.participantId}</LinkText>
                  </Link>
                </Td>
                <Td>{participant.siteCountry}</Td>
                <Td>{participant.siteName}</Td>
                <Td>
                  {participant.startDate
                    ? formatDate(participant.startDate)
                    : ""}
                </Td>
                <Td>
                  {participant.withdrawn && participant.endDate
                    ? formatDate(participant.endDate)
                    : "N/A"}
                </Td>
                <Td>{participant.lastQuestionnaireStatus || "N/A"}</Td>
                <Td>{participant.countOtherSeizures > 0 ? "Yes" : "No"}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ParticipantsTable;
