import { Text } from "@chakra-ui/react";
import React from "react";

export const LinkText = ({ children }) => (
  <Text textColor="blue.500">{children}</Text>
);

export const EmphasisText = ({ children, ...extraParams }) => (
  <Text {...extraParams} fontWeight="extrabold">
    {children}
  </Text>
);

export const ErrorText = ({ children }) => (
  <Text textColor="red.600">{children}</Text>
);

export const Subtitle = ({ children }) => (
  <Text fontSize="2xl">{children}</Text>
);

export const NoteText = ({ children }) => <Text fontSize="sm" textColor="gray.700">{children}</Text>;
