import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Input, Spacer, useDisclosure } from "@chakra-ui/react";
import StudySummary from "../components/study/StudySummary";
import SearchParticipants from "../components/participants/SearchParticipants";
import ParticipantsTable from "../components/participants/ParticipantsTable";
import { StudyContext } from "../services/study/studyContext";
import ModalDialogue from "../components/common/ModalDialogue";
import FlexRow from "../components/common/FlexRow";
import { getParticipantsOverview } from "../api/participants";

const ParticipantsList = () => {
  const study = useContext(StudyContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const sortOrder = useRef("ASC");
  const filterField = useRef(null);
  const filterText = useRef(null);
  const [participants, setParticipants] = useState([]);
  const [participantsShown, setParticipantsShown] = useState([]);
  const {
    isOpen: isFilterOpen,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const [hasFiltered, setHasFiltered] = useState(false);

  const handleSortBy = (fieldName, dataType) => {
    sortOrder.current = sortOrder.current === "ASC" ? "DESC" : "ASC";
    const temp = [...participantsShown];
    if (dataType === "string") {
      temp.sort((a, b) => {
        const [left, right] = sortOrder.current === "ASC" ? [a, b] : [b, a];
        return left[fieldName].localeCompare(right[fieldName]);
      });
    } else if (dataType === "date") {
      temp.sort((a, b) => {
        const [left, right] = sortOrder.current === "ASC" ? [a, b] : [b, a];
        return new Date(left[fieldName]).getTime() - new Date(right[fieldName]).getTime();
      });
    } else {
      temp.sort((a, b) => {
        const [left, right] = sortOrder.current === "ASC" ? [a, b] : [b, a];
        return left[fieldName] - right[fieldName];
      });
    }
    setParticipantsShown([...temp]);
  };

  const handleFilterBy = (fieldName) => {
    filterField.current = fieldName;
    onOpenFilter();
  };

  const doFilter = () => {
    const text = filterText.current.value;
    if (text && text.length > 0) {
      const filtered = [...participants].filter((p) =>
        p[filterField.current].toLowerCase().includes(text.toLowerCase())
      );
      setParticipantsShown([...filtered]);
      setHasFiltered(true);
    }
    onCloseFilter();
  };

  const handleSearch = (text) => {
    if (text && text.length > 0) {
      const filtered = [...participants].filter(
        (p) =>
          p["participantId"].toLowerCase().includes(text.toLowerCase()) ||
          p["siteCountry"].toLowerCase().includes(text.toLowerCase()) ||
          p["siteName"].toLowerCase().includes(text.toLowerCase())
      );
      setParticipantsShown([...filtered]);
      setHasFiltered(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    getParticipantsOverview(study._id)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          const { result } = response.data;
          setParticipants([...result]);
          setParticipantsShown([...result]);
          setError(false);
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        console.error("Something went wrong while trying to load the patients", err.message);
        setError(true);
      });
  }, [study]);

  return (
    <>
      <ModalDialogue
        title="Filter participants"
        onClose={onCloseFilter}
        isOpen={isFilterOpen}
        onMainAction={doFilter}
      >
        <Input type="text" ref={filterText} />
      </ModalDialogue>
      <Box>
        <StudySummary />
        <FlexRow>
          <SearchParticipants onSearch={handleSearch} />
          {hasFiltered && (
            <>
              <Spacer />
              <Button
                variant="outline"
                onClick={() => {
                  setParticipantsShown([...participants]);
                  setHasFiltered(false);
                }}
                colorScheme="orange"
              >
                Clear filter
              </Button>
            </>
          )}
        </FlexRow>
        <ParticipantsTable
          loading={loading}
          error={error}
          onHeaderClick={handleSortBy}
          participants={participantsShown}
          onFilterClick={handleFilterBy}
        />
      </Box>
    </>
  );
};

export default ParticipantsList;
