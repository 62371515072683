import React, { useEffect, useState }  from "react";
import {
  Box,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { isObject } from "lodash";
import { useParams } from "react-router-dom";
import { ErrorText } from "../components/common/Texts";
import ParticipantOverview from "../components/participant/ParticipantOverview";
import ParticipantSummary from "../components/participant/ParticipantSummary";
import QuestionnairesTable from "../components/participant/QuestionnairesTable";
import SeizuresGraph from "../components/participant/SeizuresGraph";
import SeizuresTable from "../components/participant/SeizuresTable";
import { getSeizuresData, mergedQuestionnaires } from "../utils";
import { get } from "../api/base";
import MovingAverageTable from "../components/participant/MovingAverageTable";

const Participant = () => {
  const { id } = useParams();
  const [participant, setParticipant] = useState();
  const [seizures, setSeizures] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    get(`/participants/${id}`)
      .then(response => {
        setLoading(false);
        if (response.status === 200) {
          setError(false);
          const { result } = response.data;
          setParticipant(result);
          if (result.seizures) {
            let patientSeizureTypes = {};
            if (
              result.lists &&
              isObject(result.lists.neurologist_seizure_types)
            ) {
              patientSeizureTypes =
                result.lists.neurologist_seizure_types;
            }
            const seizuresData = getSeizuresData(
              result.seizures,
              patientSeizureTypes
            );
            setSeizures(seizuresData);
          }
    
          if (result.answeredForms && result.pendingForms) {
            setQuestionnaires(mergedQuestionnaires(result.answeredForms, result.pendingForms));
          }
        } else {
          setError(true);
          console.error("An error has occurred while getting the participant:", response.data);
        }
      })
      .catch(err => {
        setError(true);
        console.error("An error has occurred while getting the participant:", err.message);
      });
  }, [id]);

  return (
    <Box>
      {loading && <Spinner />}
      {!loading && error && (
        <ErrorText>
          Something went wrong while loading the Participant info
        </ErrorText>
      )}
      {!loading && !error && participant && (
        <>
          <ParticipantSummary participant={participant} />
          <Tabs variant="enclosed" marginTop={10} isLazy>
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Reported seizures</Tab>
              <Tab>Questionnaires</Tab>
              <Tab>Average Seizures</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ParticipantOverview participant={participant} />
                <Box
                  w="100%"
                  overflowX="scroll"
                >
                  <SeizuresGraph seizures={seizures} questionnaires={questionnaires} />
                </Box>
              </TabPanel>
              <TabPanel>
                <SeizuresTable seizures={seizures} />
              </TabPanel>
              <TabPanel>
                <QuestionnairesTable questionnaires={questionnaires} />
              </TabPanel>
              <TabPanel>
                <MovingAverageTable seizures={seizures} startDate={participant?.startDate} endDate={participant?.endDate}/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Box>
  );
};

export default Participant;
