import axios from "axios";
import env from '../env.json';


const {REACT_APP_SERVER_URL} = env;
export const requestLogin = async (email, password) => {
    const response = await axios.post(
        `${REACT_APP_SERVER_URL}/users/login`,
        { email, password },
        { withCredentials: true }
    );
    if (response.status !== 200 && response.status !== 401) {
        return null;
    }
    return response.data;
};

export const requestLogout = async () => {
    const response = await axios.post(
        `${REACT_APP_SERVER_URL}/users/logout`,
        null,
        { withCredentials: true }
    );

    if (response.status !== 200) {
        return null;
    }

    return response.data;
};

export const requestMe = async () => {
    const response = await axios.get(
        `${REACT_APP_SERVER_URL}/users/me`,
        { withCredentials: true, headers: { 'Content-Type': 'application/json' } }
    );
    if (response.status !== 200) {
        return null;
    }

    return response.data;
}
