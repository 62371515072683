import React, { useState } from "react";
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import FlexRow from "../common/FlexRow";

const SearchParticipants = ({ onSearch }) => {
  const [text, setText] = useState("");
  return (
    <FlexRow>
      <InputGroup width="600px" marginY={4}>
        <Input
          placeholder="Find participants by ID, Site or Country"
          size="lg"
          onChange={(e) => { setText(e.target.value); }}
        />
        <InputRightElement children={<Search2Icon />} />
      </InputGroup>
      <Button colorScheme="blue" size="lg" onClick={() => { onSearch(text); }}>Search</Button>
    </FlexRow>
  );
};

export default SearchParticipants;
