import { Box, Table, Td, Th, Tr } from "@chakra-ui/react";
import React from "react";
import { formatTimestamp } from "../../utils";

const getWeekNumber = (answeredForms, pendingForms) => {
  const now = new Date();
  let formsThatApply = Array.isArray(pendingForms) ? pendingForms.filter(form => new Date(form.send_date) <= now).length : 0;
  if (Array.isArray(answeredForms)) {
    formsThatApply += answeredForms.length;
  }

  return formsThatApply;
};

const getQuestionnairesCompletionRate = (answeredForms, pendingForms) => {
  if (Array.isArray(pendingForms) || Array.isArray(answeredForms)) {
    const now = new Date();
    let total = Array.isArray(pendingForms) ? pendingForms.filter(form => new Date(form.send_date) <= now).length : 0;
    const answered = Array.isArray(answeredForms) ? answeredForms.length : 0;
    total += answered;
    const percentage = ((answered * 100) / total).toFixed(2);
    return `${percentage}%`;
  }

  return "N/A";
};

const getLastSentFormDate = (answeredForms, pendingForms) => {
  if (Array.isArray(answeredForms) || Array.isArray(pendingForms)) {
    let lastDate = new Date(0);
    const now = new Date();

    if (Array.isArray(pendingForms)) {
      for (const { send_date } of pendingForms) {
        const sendDate = new Date(send_date);
        if (sendDate < now && sendDate > lastDate) {
          lastDate = sendDate;
        }
      }
    }

    if (Array.isArray(answeredForms)) {
      for (const { studyParameters } of answeredForms) {
        const sendDate = new Date(studyParameters.send_date);
        if (sendDate < now && sendDate > lastDate) {
          lastDate = sendDate;
        }
      }
    }

    return formatTimestamp(lastDate);
  }

  return "N/A";
};

const ParticipantOverview = ({ participant }) => {
  const { lastRequestTimestamp, settings, answeredForms, pendingForms } =
    participant;

  return (
    <Box w="100%">
      <Table>
        <Tr>
          <Th>Week number</Th>
          <Td>{getWeekNumber(answeredForms, pendingForms)}</Td>
          <Th>Last Activity</Th>
          <Td>{formatTimestamp(lastRequestTimestamp)}</Td>
          <Th>Last reminder</Th>
          <Td>{ getLastSentFormDate(answeredForms, pendingForms) }</Td>
        </Tr>
        <Tr>
          <Th>Language</Th>
          <Td>{settings.language ? settings?.language?.toUpperCase() : "N/A"}</Td>
          <Th>Questionnaires compliance</Th>
          <Td>
            {getQuestionnairesCompletionRate(answeredForms, pendingForms)}
          </Td>
          <Td></Td><Td></Td>
        </Tr>
      </Table>
    </Box>
  );
};

export default ParticipantOverview;
