/**
 * Utilities
 */

 import { SUPPORTED_SEIZURE_TYPES } from "./seizureTypes";
 import dayjs from "dayjs";
 
 export const formatDate = (isoDateAsString) => (dayjs(isoDateAsString)).format("DD-MMM-YYYY");
 
 export const formatTimestamp = (timestamp) => (dayjs(timestamp)).format("DD-MMM-YYYY HH:mm");
 
 export const normalizeAnsweredForm = (answeredForm) => {
   // { questions: [{answer_type: "radio", answer: "no"}, {answer_type: "section"}], studyParameters: {send_date: ""} }
 
   const date = answeredForm.studyParameters.send_date;
   const answer = answeredForm.questions.find((q) => q.answer_type === "radio");
 
   return {
     date: new Date(date),
     answer: answer ? answer?.answer?.toUpperCase() : "N/A",
     status: "Answered",
   };
 };
 
 export const mergedQuestionnaires = (answeredForms, pendingForms) => {
   const now = new Date();
   // both have same shape: [{date, answer, status}]
   const answers = answeredForms.map(normalizeAnsweredForm);
   const pending = pendingForms.map(({ send_date }) => ({
     answer: "N/A",
     date: new Date(send_date),
     status: new Date(send_date) < now ? "Not answered" : "Not sent yet",
   }));
 
   const merged = [...answers, ...pending];
   merged.sort((a, b) => a.date.getTime() - b.date.getTime());
   return merged;
 };
 
 export const getSeizuresData = (seizures, patientSeizureTypes) => {
   const data = [];
 
   for (const seizure of seizures) {
     const { date, seizure_type, seizure_type_id } = seizure;
     const seizureData = { date };
     if (seizure_type in SUPPORTED_SEIZURE_TYPES) {
       seizureData["seizureType"] = SUPPORTED_SEIZURE_TYPES[seizure_type];
       seizureData["participantsNomenclature"] =
         SUPPORTED_SEIZURE_TYPES[seizure_type];
     } else {
       let seizureType = patientSeizureTypes.find(
         (st) => st.patientName === seizure_type || st.id === seizure_type_id
       );
       if (seizureType) {
         seizureData["seizureType"] =
           seizureType.seizureType in SUPPORTED_SEIZURE_TYPES
             ? SUPPORTED_SEIZURE_TYPES[seizureType.seizureType]
             : "Other";
         seizureData["participantsNomenclature"] = seizureType.patientName;
       } else {
         seizureData["seizureType"] = "Other";
         seizureData["participantsNomenclature"] = seizure_type;
       }
     }
 
     data.push(seizureData);
   }
 
   return data;
 };
 
 export const groupByMonthAndWeek = (dates, startDate) => {
  const groups = {};
  const start = new Date(startDate);

  dates.forEach((date) => {
    const diffInDays = Math.floor((date - start) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.floor(diffInDays / 7);

    if (!groups[weekNumber]) {
      groups[weekNumber] = 1;
    } else {
      groups[weekNumber]++;
    }
  });

  return groups;
};
 
 
 export const calculateMovingAverage = (groups, startDate, endDate = new Date()) => {
  const result = [];
  const start = new Date(startDate);
  const end = new Date(endDate);

  let current = new Date(start);
  let week = 0;

  while (current <= end) {
    const month = Math.ceil((week + 1) / 4);
    const seizuresThisWeek = groups[week] || 0;
    const averageSeizuresPerWeek = seizuresThisWeek;

    let movingAverage = 'NA';

    if (week >= 3) {
      const pastFourWeeksCounts = [];

      for (let i = 0; i < 4; i++) {
        const pastWeek = week - i;
        pastFourWeeksCounts.push(groups[pastWeek] || 0);
      }

      movingAverage = pastFourWeeksCounts.reduce((a, b) => a + b, 0) / 4;
      movingAverage = movingAverage.toFixed(2);
    }

    result.push({
      Month: month.toString(),
      Week: (week + 1).toString(),
      movingAverage: movingAverage,
      averageSeizuresPerWeek: averageSeizuresPerWeek.toFixed(0),
    });

    week++;
    current.setDate(current.getDate() + 7);
  }

  return result;
};












 
 
 