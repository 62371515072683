import axios from "axios";
import env from '../env.json'

const {REACT_APP_SERVER_URL} = env;

export const get = (endpoint) => axios.get(`${REACT_APP_SERVER_URL}${endpoint}`,
    {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' }
    }
);

export const post = (endpoint, data) => axios
    .post(
        `${REACT_APP_SERVER_URL}${endpoint}`,
        data,
        {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' },
        }
    );
