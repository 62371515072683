import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  useDisclosure,
  Input,
  Spacer,
  Button,
} from "@chakra-ui/react";
import { formatTimestamp } from "../../utils";
import NoContentBox from "../common/NoContentBox";
import FlexRow from "../common/FlexRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faFilter } from "@fortawesome/free-solid-svg-icons";
import ModalDialogue from "../common/ModalDialogue";
import { useRef } from "react";

const SeizuresTable = ({ seizures }) => {
  const [sortOrder, setSortOrder] = useState("DESC");
  const [strongTypedSeizures, setStrongTypedSeizures] = useState([]);
  const headers = [
    {
      title: "Seizure Type",
      fieldName: "seizureType",
      canFilter: true,
      dataType: "string",
    },
    {
      title: "Participant's Nomenclature",
      fieldName: "participantsNomenclature",
      canFilter: true,
      dataType: "string",
    },
    {
      title: "Date and Time of the seizure occurence",
      fieldName: "date",
      canFilter: false,
      dataType: "date",
    },
    {
      title: "Date and Time of the seizure entry",
      fieldName: "createdAt",
      canFilter: false,
      dataType: "date"
    }
  ];
  const {
    isOpen: isFilterOpen,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const filterField = useRef(null);
  const filterText = useRef(null);
  const [hasFiltered, setHasFiltered] = useState(false);

  useEffect(() => {
    if (seizures) {
      setStrongTypedSeizures(
        seizures.map(s => ({...s, date: new Date(s.date)}))
      );
    }
    
  }, [seizures])

  const onHeaderClick = (fieldName, dataType) => {
    const temp = [...strongTypedSeizures];
    if (dataType === "string") {
      temp.sort((a, b) => {
        if (sortOrder === "ASC") {
          return a[fieldName].localeCompare(b[fieldName]);
        }

        return b[fieldName].localeCompare(a[fieldName]);
      });
    } else {
      temp.sort((a, b) => {
        if (sortOrder === "ASC") {
          return a[fieldName] - b[fieldName];
        }
        return b[fieldName] - a[fieldName];
      });
    }
    setStrongTypedSeizures([...temp]);
    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
  };

  const onFilterClick = (fieldName) => {
    filterField.current = fieldName;
    onOpenFilter();
  };

  const doFilter = () => {
    const text = filterText.current.value;
    if (text && text.length > 0) {
      const filtered = seizures
        .filter(s => 
          s[filterField.current].toLowerCase().includes(text.toLowerCase()))
        .map(s => ({...s, date: new Date(s.date)}));
      setStrongTypedSeizures([...filtered]);
      setHasFiltered(true);
    }
    onCloseFilter();    
  }

  return (
    <>
        <>
        <ModalDialogue
        title="Filter seizures"
        onClose={onCloseFilter}
        isOpen={isFilterOpen}
        onMainAction={doFilter}
      >
        <Input type="text" ref={filterText} />
      </ModalDialogue>
      {hasFiltered && (
            <>
              <Spacer />
              <Button
                variant="outline"
                onClick={() => {
                  setStrongTypedSeizures([...seizures].map(s => ({...s, date: new Date(s.date)})));
                  setHasFiltered(false);
                }}
                colorScheme="orange"
              >
                Clear filter
              </Button>
            </>
          )}
        <TableContainer marginY={6} overflowX="unset" overflowY="unset">
          <Table variant="simple">
            <Thead backgroundColor="blue.100" position="sticky" top={0} zIndex="docked">
              <Tr>
              {headers.map((header) => (
              <Th>
                <FlexRow>
                  <FontAwesomeIcon icon={faArrowUp} />
                  <FontAwesomeIcon icon={faArrowDown} />
                  <Text
                    cursor="pointer"
                    onClick={() => {
                      onHeaderClick(header.fieldName, header.dataType);
                    }}
                    marginLeft={2}
                  >
                    {header.title}
                  </Text>
                  {header.canFilter && (
                    <FontAwesomeIcon
                      icon={faFilter}
                      size="lg"
                      cursor="zoom-in"
                      onClick={() => {
                        onFilterClick(header.fieldName);
                      }}
                      style={{ marginLeft: "4px" }}
                    />
                  )}
                </FlexRow>
              </Th>
            ))}
              </Tr>
            </Thead>
            <Tbody>
              {strongTypedSeizures.map((seizure) => (
                <Tr>
                  <Td>{seizure.seizureType}</Td>
                  <Td>{seizure.participantsNomenclature}</Td>
                  <Td>{formatTimestamp(seizure.date)}</Td>
                  <Td>{formatTimestamp(seizure.createdAt)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </>
      {(!seizures || seizures.length === 0) && (
        <NoContentBox>No seizures have been reported</NoContentBox>
      )}
    </>
  );
};

export default SeizuresTable;
