import React, { useContext, useState } from "react";
import { Box, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { LoginButton } from "../components/auth/Login";
import { UserContext } from "../services/auth/userContext";
import { ErrorText, LinkText } from "../components/common/Texts";
import { Link } from "react-router-dom";

const LoginView = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const { emailPasswordLogin, isLoading, error } = useContext(UserContext);

  return (
    <Box display="flex" justifyContent="center" width="100%" height="60%">
      <Box
        borderRadius="lg"
        shadow="lg"
        alignSelf="center"
        width="600px"
        p={10}
      >
        <Text fontSize="2xl" textAlign="center">
          Dashboard Login
        </Text>
        <>
          <FormControl>
            <FormLabel>Email Address</FormLabel>
            <Input
              size="lg"
              onChange={(e) => {
                setCredentials({ ...credentials, email: e.target.value });
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              size="lg"
              type="password"
              onChange={(e) => {
                setCredentials({ ...credentials, password: e.target.value });
              }}
            />
          </FormControl>
          {error && error.hasError && (
            <FormControl>
              <ErrorText>{error.message}</ErrorText>
            </FormControl>
          )}
          <FormControl marginTop={10} display="flex" justifyContent="center">
            <LoginButton
              onLogin={() => {
                emailPasswordLogin(credentials.email, credentials.password);
              }}
              isLoading={isLoading}
            />
          </FormControl>
        </>
        <Box display="flex" justifyContent="flex-end">
          <LinkText>
            <Link to="/passwordinstructions">Forgot password</Link>
          </LinkText>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginView;
