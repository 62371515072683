import React, { useRef } from "react";
import { Box, Button, useDisclosure, useToast } from "@chakra-ui/react";
import ModalDialogue from "../common/ModalDialogue";
import InvitationForm from "./InvitationForm";
import { post } from "../../api/base";

const ActionsBar = ({ onSuccesfulInvitation }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const invitationEmailRef = useRef();
  const toast = useToast();

  const sendInvitation = () => {
    console.log("Sending invitation to", invitationEmailRef.current);
    if (!invitationEmailRef.current) {
      return;
    }

    post("/invitations/", { email: invitationEmailRef.current })
      .then((response) => {
        if (response.status === 201) {
          toast({
            title: "Invitation created.",
            description: "Invitation has been sent to the person",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onSuccesfulInvitation(response.data.result);
        } else {
          toast({
            title: "Error inviting person.",
            description: `An error has occurred inviting the person: ${response.data.result}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }

        onClose();
      })
      .catch((err) => {
        console.error("Error creating invitation:", err.message);
        toast({
          title: "Error inviting person.",
          description: `An error has occurred inviting the person: ${err.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <ModalDialogue
        title="Invite Person to Study Dashboard"
        isOpen={isOpen}
        onClose={onClose}
        onMainAction={sendInvitation}
        buttonText="Invite person"
      >
        <InvitationForm emailRef={invitationEmailRef} />
      </ModalDialogue>
      <Box marginY={4}>
        <Button colorScheme="blue" onClick={onOpen}>
          Invite Person
        </Button>
      </Box>
    </>
  );
};

export default ActionsBar;
