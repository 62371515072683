import React from "react";
import FlexRow from "./FlexRow";

const SubHeader = ({ children }) => (
  <>
    <FlexRow marginY="10px">{children}</FlexRow>
    <hr style={{ borderWidth: "1px" }} />
  </>
);

export default SubHeader;
