import { Box, Table, Td, Th, Tr } from "@chakra-ui/react";
import React from "react";
import { useContext } from "react";
import { StudyContext } from "../../services/study/studyContext";
import PartnerLogo from "../common/PartnerLogo";
import SubHeader from "../common/SubHeader";

const StudySummary = () => {
  const study = useContext(StudyContext);

  return (
    <SubHeader>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        marginY={4}
      >
        <Table variant="unstyled" size="sm">
          <Tr>
            <Th width="20%">Study</Th>
            <Td>{study.name}</Td>
          </Tr>
          <Tr>
            <Th>Study ID</Th>
            <Td>{study.studyId}</Td>
          </Tr>
        </Table>
        <PartnerLogo img={study.logo} />
      </Box>
      <hr style={{ borderWidth: "1px" }} />
    </SubHeader>
  );
};

export default StudySummary;
