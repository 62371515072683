import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Spacer, Spinner } from "@chakra-ui/react";
import dayjs from "dayjs";
import { SEIZURE_TYPES_COLOR } from "../../utils/seizureTypes";
import FlexRow from "../common/FlexRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Seizures overview",
    },
  },
};

const WEEKS_IN_GRAPH = 12;

const NavigationArrows = ({onClickLeft, onClickRight}) => <FlexRow>
  <FontAwesomeIcon icon={faChevronLeft} size="4x" onClick={onClickLeft} />
  <Spacer />
  <FontAwesomeIcon icon={faChevronRight} size="4x" onClick={onClickRight} />
</FlexRow>;

// From startDate you get 6 weeks after
const getSeizuresDataSets = (seizures, startDate, length = WEEKS_IN_GRAPH) => {
  const seizureTypes = {};

  let weekEnds = dayjs(startDate);
  let weekStarts = weekEnds.subtract(7, "days");

  for (let i = 0; i < length; i++) {
    const seizuresInRange = seizures.filter((s) => {
      const date = dayjs(s.date);
      return date.isAfter(weekStarts) && date.isBefore(weekEnds);
    });

    for (const seizure of seizuresInRange) {
      if (!(seizure.seizureType in seizureTypes)) {
        seizureTypes[seizure.seizureType] = new Array(length).fill(0);
      }

      seizureTypes[seizure.seizureType][i] += 1;
    }
    weekEnds = weekEnds.add(7, "days");
    weekStarts = weekStarts.add(7, "days");
  }

  // Needed to paint something when no seizures are reported
  if (Object.keys(seizureTypes).length === 0) {
    return [
      {
        label: "",
        data: new Array(length).fill(0),
        backgroundColor: "white",
      },
    ];
  }

  return Object.entries(seizureTypes).map(([seizureType, counts]) => ({
    label: seizureType,
    data: counts,
    backgroundColor: SEIZURE_TYPES_COLOR[seizureType] || "gray",
  }));
};

// Questionnaires should already be sorted by date
const SeizuresGraph = ({ seizures, questionnaires }) => {
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [loading, setLoading] = useState(false);
  const currentLimit = useRef(WEEKS_IN_GRAPH);

  const setGraphData = (startIndex, endIndex) => {
    setLoading(true);

    const labels = [];
    for (let i = startIndex; i < endIndex; i++) {
      if (!questionnaires[i])
        continue;
      const status =
        questionnaires[i] &&  questionnaires[i].status === "Answered"
          ? questionnaires[i].answer
          : questionnaires[i].status;
      labels.push(`Week ${i + 1} - ${status}`);
    }
    const datasets = getSeizuresDataSets(
      seizures,
      questionnaires[startIndex].date
    );

    setData({
      labels,
      datasets,
    });
    
    setLoading(false);
  }

  useEffect(() => {
    if (seizures && questionnaires.length > 0) {
      setGraphData(0, WEEKS_IN_GRAPH);
    }
  }, [seizures, questionnaires]);

  const handleNavigation = (newLimit) => {
    setGraphData(newLimit - WEEKS_IN_GRAPH, newLimit);
    currentLimit.current = newLimit;
  };

  return (
    <>
      {loading && <Spinner />}
      {!loading && data && 
      <>
        <Bar options={options} data={data} height="70vh" />
        <NavigationArrows
          onClickLeft={() => {
            const newLimit = currentLimit.current - WEEKS_IN_GRAPH > WEEKS_IN_GRAPH ? currentLimit.current - WEEKS_IN_GRAPH : WEEKS_IN_GRAPH ;
            handleNavigation(newLimit);
          }}
          onClickRight={() => {
            if (currentLimit.current < questionnaires.length) {
              const newLimit = currentLimit.current + WEEKS_IN_GRAPH > questionnaires.length ? questionnaires.length : currentLimit.current + WEEKS_IN_GRAPH;
              handleNavigation(newLimit);
            }
          }} />
      </>}
    </>
  );
};

export default SeizuresGraph;
