export const SUPPORTED_SEIZURE_TYPES = {
    "focal_aware_motoronset_seizure": "Focal aware motor onset seizure",
    "focal_aware_nonmotor_onset_seizure": "Focal aware non-motor onset seizure",
    "focal_impaired_awareness_seizure": "Focal impaired awareness seizures",
    "focal_to_bilateral_tonicclonic_seizure": "Focal to bilateral tonic-clonic seizures",
    "focal_with_motor_cluster": "Focal aware seizures with motor signs - Too many to count (Cluster)",
    "focal_without_motor_cluster": "Focal aware seizures without motor signs - Too many to count (cluster)",
    "focal_impaired_cluster": "Focal impaired awareness seizures - Too many to count (cluster)",
    "focal_bilateral_cluster": "Focal to bilateral tonic-clonic seizures - Too many to count (cluster)",
    "focal_aware_seizure": "Focal aware seizure (general)"
};

export const SEIZURE_TYPES_COLOR = {
    "Focal aware motor onset seizure": "green",
    "Focal aware non-motor onset seizure": "blue",
    "Focal impaired awareness seizures": "red",
    "Focal to bilateral tonic-clonic seizures": "yellow",
    "Focal aware seizures with motor signs - Too many to count (Cluster)": "pink",
    "Focal aware seizures without motor signs - Too many to count (cluster)": "purple",
    "Focal impaired awareness seizures - Too many to count (cluster)": "brown",
    "Focal to bilateral tonic-clonic seizures - Too many to count (cluster)": "orange",
    "Focal aware seizure (general)": "black",
    "Other": "gray",
};