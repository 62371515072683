import { createContext, useState } from "react";
import { loginEmailPassword, fetchCurrentUser, logoutCurrentUser } from ".";
import { post } from "../../api/base";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({ hasError: false, message: "" });

    const emailPasswordLogin = async (email, password) => {
        setIsLoading(true);
        setError({ hasError: false, message: "" });
        const loginResult = await loginEmailPassword(email, password);
        setIsLoading(false);
        if (loginResult === null || !loginResult.success) {
            if (loginResult && loginResult.result === 'PasswordExpired') {
                setError({ hasError: true, message: "Password Expired" });
                sendRequest(email)
            }
            else
                setError({ hasError: true, message: "Invalid credentials" });
            return ;
        }
        const { result } = loginResult;
        setUser(result);
    };

    const fetchUser = async () => {
        setError({ hasError: false, message: "" });
        setIsLoading(true);
        const authUserResult = await fetchCurrentUser();
        const user = authUserResult && authUserResult.result ? authUserResult.result : null;
        setUser(user);
        setIsLoading(false);
    }

    const logOutUser = async () => {
        setIsLoading(true);
        await logoutCurrentUser();
        setUser(null);
        setIsLoading(false);
    }

    const sendRequest = (email) => {
        setIsLoading(true);
        post("/passwordresets/", { email })
          .then((response) => {
            setIsLoading(false);
            if (response.status !== 200) {
              setError(
                "An error has occurred. Please try again, if it persists, please contact the Neuroventis team"
              );
              return;
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.error("Error while sending request", err.message);
            setError(
              "An error has occurred. Please try again, if it persists, please contact the Neuroventis team"
            );
          });
      };

    return <UserContext.Provider value={{ user, setUser, isLoading, fetchUser, emailPasswordLogin, logOutUser, error }}>
        {children}
    </UserContext.Provider>;
};
