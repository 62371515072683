import { Spacer } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { get } from "../api/base";
import ActionsBar from "../components/admin/ActionsBar";
import InvitationsTable from "../components/admin/InvitationsTable";
import UsersTable from "../components/admin/UsersTable";

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [invitations, setInvitations] = useState([]);

  useEffect(() => {
    get("/users/")
      .then((response) => {
        if (response.status === 200) {
          const { result } = response.data;
          setUsers(result);
        }
      })
      .catch((err) => {
        console.error("Error getting users", err.message);
      });

      get("/invitations/")
      .then((response) => {
        if (response.status === 200) {
          const { result } = response.data;
          setInvitations(result);
        }
      })
      .catch((err) => {
        console.error("Error getting users", err.message);
      });
  }, []);

  const onSuccessfulInvitation = (data) => {
    setInvitations([...invitations, data]);
  };

  return (
    <>
      <ActionsBar onSuccesfulInvitation={onSuccessfulInvitation} />
      <UsersTable users={users} />
      <Spacer />
      <InvitationsTable invitations={invitations} />
    </>
  );
};

export default Admin;
