import { Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { formatDate } from "../../utils";
import FlexRow from "../common/FlexRow";
import PartnerLogo from "../common/PartnerLogo";
import SubHeader from "../common/SubHeader";
import { EmphasisText } from "../common/Texts";

const TextWMargin = ({ children }) => <Text marginRight="4px">{children}</Text>;

const ParticipantSummary = ({ participant, studyImg }) => {
  return (
    <SubHeader>
      <FlexRow minW="60%" justifyContent="space-between">
        <FlexRow>
          <TextWMargin>Participant ID:</TextWMargin>
          <EmphasisText>{participant.participantId}</EmphasisText>
        </FlexRow>
        <FlexRow>
          <TextWMargin>Site: </TextWMargin>
          <EmphasisText>{participant.site.siteId}</EmphasisText>
        </FlexRow>
        <FlexRow>
          <TextWMargin>Country: </TextWMargin>
          <EmphasisText>{participant.siteCountry}</EmphasisText>
        </FlexRow>
        <FlexRow>
          <TextWMargin>Account Creation Date: </TextWMargin>
          <EmphasisText>
            {participant.date ? formatDate(participant.date) : "N/A"}
          </EmphasisText>
        </FlexRow>
      </FlexRow>
      <Spacer />
      <PartnerLogo img={studyImg}/>
    </SubHeader>
  );
};

export default ParticipantSummary;
