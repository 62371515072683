import React, { useContext } from "react";
import { Button } from "@chakra-ui/react";
import { UserContext } from "../../services/auth/userContext";

export const LoginButton = ({ onLogin, isLoading }) => (
  <Button colorScheme="blue" onClick={onLogin} isLoading={isLoading} size="lg">
    Login
  </Button>
);

export const LogoutButton = () => {
  const { logOutUser } = useContext(UserContext);

  return (
    <Button
      colorScheme="orange"
      onClick={() => {
        logOutUser();
      }}
    >
      Logout
    </Button>
  );
};
