import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { formatTimestamp } from "../../utils";
import { Subtitle } from "../common/Texts";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InvitationsTable = ({ invitations }) => {
  return (
    <>
      <Subtitle>Invitations</Subtitle>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Email</Th>
              <Th>Sent on</Th>
              <Th>Expires on</Th>
              <Th>Accepted on</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invitations.map((invitation) => (
              <Tr key={invitation._id}>
                <Td>{invitation.acceptedAt && <FontAwesomeIcon color="green" icon={faCheckCircle} /> }</Td>
                <Td>{invitation.email}</Td>
                <Td>{formatTimestamp(invitation.sentAt)}</Td>
                <Td>{formatTimestamp(invitation.expirationTime)}</Td>
                <Td>{ invitation.acceptedAt ? formatTimestamp(invitation.acceptedAt) : "Not yet" }</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InvitationsTable;
