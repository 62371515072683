import {
  FormControl,
  FormLabel,
  Input,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { ErrorText } from "../common/Texts";

const SetPassword = ({ passwordRef, setIsValid }) => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState("");
  const validPasswordRegex = new RegExp(
    /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^\(\)\[\]?=*&]).{8,})/
  );

  useEffect(() => {
    if (password !== repeatPassword) {
      setIsValid(false);
      setError("Passwords do not match");
    } else if (validPasswordRegex.test(password)) {
      setIsValid(true);
      setError("");
      passwordRef.current = password;
    } else {
      setIsValid(false);
      setError("Password criteria not met");
    }
  }, [password, repeatPassword]);

  return (
    <>
      <FormControl>
        <FormLabel>New Password:</FormLabel>
        <Input
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Repeat Password:</FormLabel>
        <Input
          type="password"
          onChange={(e) => {
            setRepeatPassword(e.target.value);
          }}
        />
      </FormControl>
      {error.length > 0 && <ErrorText>{error}</ErrorText>}
      <Text>Your password should contain:</Text>
      <UnorderedList>
        <ListItem>Minimum 8 characters { password.length > 7 && <FontAwesomeIcon color="green" icon={faCheckCircle} /> }</ListItem>
        <ListItem>At least 1 number { /[\d+]/.test(password) && <FontAwesomeIcon color="green" icon={faCheckCircle} /> }</ListItem>
        <ListItem>At least 1 uppercase { /[A-Z+]/.test(password) && <FontAwesomeIcon color="green" icon={faCheckCircle} /> }</ListItem>
        <ListItem>At least 1 lowercase { /[a-z+]/.test(password) && <FontAwesomeIcon color="green" icon={faCheckCircle} /> }</ListItem>
        <ListItem>At least one special character { /[!@#$%^\(\)\[\]?=*&]/.test(password) && <FontAwesomeIcon color="green" icon={faCheckCircle} /> }</ListItem>
      </UnorderedList>
    </>
  );
};

export default SetPassword;
