import { Box, Button, useToast } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { post } from "../../api/base";
import { ErrorText, Subtitle } from "../common/Texts";
import ResendInvitation from "../invitation/ResendInvitation";
import SetPassword from "./SetPassword";

const CreateAccount = ({ invitation }) => {
  const passwordRef = useRef();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const isInvitationStillValid =
    new Date(invitation.expirationTime) > new Date();
  const toast = useToast();
  const history = useHistory();

  const createAccount = () => {
    setIsLoading(true);
    setError("");
    post(`/invitations/accept/${invitation.token}`, {
      password: passwordRef.current,
    })
      .then((response) => {
        setIsLoading(false);
        if (response.status !== 201) {
          console.error("Error accepting invitation: ", response.statusText);
          setError(
            "An error has occurred, please try again, if it persists contact the Neuroventis team"
          );
          return;
        }
        setSuccess(true);

        setTimeout(() => {
          history.push("/login");
        }, 5000);

        toast({
          title: "Account created.",
          description:
            "Your new account has been created successfully. You'll be redirected to the login page soon",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error("Error accepting invitation: ", err.message);
        setError(
          "An error has occurred, please try again, if it persists contact the Neuroventis team"
        );
        setIsLoading(false);
      });
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box width="2xl">
        <Subtitle>Create new account</Subtitle>
        {error.length > 0 && <ErrorText>{error}</ErrorText>}
        {!isInvitationStillValid && (
          <>
            <ErrorText>
              Cannot proceed: the invitation has already expired
            </ErrorText>
            <ResendInvitation invitation={invitation} />
          </>
        )}
        {invitation.acceptedAt && (
          <ErrorText>
            Cannot proceed: the invitation has been already accepted.
          </ErrorText>
        )}
        {!invitation.acceptedAt && isInvitationStillValid && (
          <>
            <SetPassword
              passwordRef={passwordRef}
              setIsValid={setIsPasswordValid}
            />
            <Button
              disabled={!isPasswordValid || success}
              colorScheme="blue"
              onClick={createAccount}
              isLoading={isLoading}
            >
              Create account
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CreateAccount;
