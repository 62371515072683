import { requestLogin, requestLogout, requestMe } from "../../api/auth";

export const loginEmailPassword = async (email, password) => {
    try {
        return await requestLogin(email, password);
    } catch (err) {
        if (err.response.status === 401)
            return err.response.data
        else
            return null
    }
};

export const logoutCurrentUser = async () => {
    await requestLogout();
    return true;
};


export const fetchCurrentUser = async () => {
    try {
        const user = await requestMe();
        return user;
    } catch (err) {
        console.error("Error fetching current user", err.message);
        return null;
    }
};
