import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { post } from "../api/base";
import { EmphasisText, ErrorText, LinkText, Subtitle } from "../components/common/Texts";

const PasswordReset = () => {
  const emailRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const sendRequest = () => {
    setIsLoading(true);
    setError(null);
    post("/passwordresets/", { email: emailRef.current })
      .then((response) => {
        setIsLoading(false);
        if (response.status !== 200) {
          setError(
            "An error has occurred. Please try again, if it persists, please contact the Neuroventis team"
          );
          return;
        }

        setIsCompleted(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error while sending request", err.message);
        setError(
          "An error has occurred. Please try again, if it persists, please contact the Neuroventis team"
        );
      });
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box width="2xl">
        <Subtitle>Reset your password</Subtitle>
        {isLoading && <Spinner />}
        {!isCompleted && (
          <>
            <FormControl marginBottom="10px">
              <FormLabel>
                Please indicate your email address to send you the instructions
                to reset your password.
              </FormLabel>
              {!isLoading && error && <ErrorText>{error}</ErrorText>}
              <Input
                type="email"
                onChange={(e) => {
                  // very simple and "dumb" => a@b.c
                  const text = e.target.value;
                  if (text.length > 4 && text.includes("@") && text.includes(".")) {
                    emailRef.current = e.target.value;
                    setIsEmailValid(true);
                  } else {
                    setIsEmailValid(false);
                  }
                }}
              />
              { !isEmailValid && <ErrorText>Please specify a valid email address</ErrorText> }
            </FormControl>

            <Box display="flex" justifyContent="space-between">
              <Button colorScheme="blue" isLoading={isLoading} disabled={!isEmailValid} onClick={sendRequest}>
                Send instructions
              </Button>
              <LinkText>
                <Link to="/login">Back to login</Link>
              </LinkText>
            </Box>
          </>
        )}
        { isCompleted && <EmphasisText>An email with the intructions has been sent to your email address</EmphasisText> }
      </Box>
    </Box>
  );
};

export default PasswordReset;
